import React from "react"
import { useCountUp } from 'react-countup'

import HexGroupSvg from "../assets/svg/hex-simple-opt.svg"

import { HexSimpleNumMain } from "../styles/common.styles"

export const HexSimpleNum = ({ number, label, symbol, isVisible }) => {
  const { countUp, start, reset } = useCountUp({
    start: 1,
    end: number,
    duration: 4,
  });

  React.useEffect(() => {
    if (isVisible) {
      start();
    }

    if (!isVisible) {
      reset();
    }

  }, [isVisible])


  return (
    <HexSimpleNumMain>
      <div className="hex-inner-box">
        <HexGroupSvg />
        <div className="hex-num-inner">
          <div className="hex-num">
            <span>{countUp}</span>
            <span>{symbol}</span>
          </div>

          <div className="hex-num-label">
            {label}
          </div>
        </div>
      </div>
    </HexSimpleNumMain>
  )
}

export default HexSimpleNum;
