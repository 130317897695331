import styled from "styled-components"

import { col, containerSideOffset } from "./common.styles"

export const TestimonialsMain = styled.div`
padding: 65px 0 50px;

.quote-item {
  ${col(13, 'c')}

  ${props => props.theme.max('lg', () => (`
    ${col(64)}
    margin-bottom: 100px;
  `))}

  ${props => props.theme.max('md', () => (`
    ${col(80)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}

  &.even {
    margin-left: auto;
    ${containerSideOffset('right', 2)}
    margin-bottom: 130px;
  }

  &.odd {
    ${containerSideOffset('left', 2)}
  }
  
  &.even,
  &.odd {
    ${props => props.theme.max('sm', () => (`
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }
    `))}
  }

  &.to-animate {
    opacity: 0;
    transition: 600ms ease 100ms;

    &.even {
      transform: translate(-100px, 0);
    }

    &.odd {
      transform: translate(100px, 0);
    }
    
    &.is-in-vp {
      transform: translate(0, 0);
      opacity: 1;
    }

    &:not(.is-in-vp) {
      transition-delay: 0ms !important;
    }
  }
}
`