import styled from "styled-components"

import { HexagonTitleMain, SectionTextMain, SectionTitleMain, containerSideOffset, col } from "../common.styles"

export const JoinOurTeamMain = styled.div`
padding: 137px 0 150px;
background-color: ${props => props.theme.colors.secondary300};

&.cutout-top {
  ${props => props.theme.max('lg', () => (`
    &:before {
      max-height: 100px;
    }
  `))}
}

${SectionTextMain} {
  max-width: 480px;
}

.title-col {
  ${containerSideOffset()}
  ${col(13, 'c')}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}

  ${SectionTitleMain} {
    margin-bottom: 104px;
  }

  ${HexagonTitleMain} {
    margin-bottom: 86px;
  }
}

.article-col-r,
.article-col-l {
  ${col(7, 'c')}

  ${props => props.theme.max('mac', () => (`
    ${col(35)}
  `))}
  
  ${props => props.theme.max('md', () => (`
    ${col(50)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
    margin-bottom: 50px;
  `))}
}

.article-col-l {
  ${containerSideOffset()}
  margin-right: auto;
}

.article-col-r {
  ${containerSideOffset('right', 4)}
  margin-left: auto;
}

.img-wrap {
  margin-bottom: 65px;
}
`