import React from "react"
import VisibilitySensor from "react-visibility-sensor"
import { QuoteBox } from "../elements/quote-box"
import { TestimonialsMain } from "../styles/testimonials.styles"

export const TestimonialsSection = ({ list }) => {
  return (
    <TestimonialsMain>
      {list.map(({ quoteText, quoteName, quoteLabel, image, imageMobile }, i) => (
        <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }} key={i}>
          {({ isVisible }) =>
            <div className={`quote-item to-animate ${isVisible ? 'is-in-vp' : ''} ${i % 2 !== 0 ? 'even' : 'odd'}`}>
              <QuoteBox
                text={quoteText}
                name={quoteName}
                label={quoteLabel}
                smallImage={image}
                smallImageMobile={imageMobile}
              />
            </div>
          }
        </VisibilitySensor>
      ))}
    </TestimonialsMain>
  )
}

