import styled from "styled-components"

import { SectionTitleMain, col, containerSideOffset, HexagonNumberMain } from "./common.styles"

export const CareersStatsMain = styled.div`
padding: 428px 0 0;
background-color: ${props => props.theme.colors.primary100};
color: #fff;

${props => props.theme.max('lt', () => (`
  padding-top: 280px;
`))}

${props => props.theme.max('sm', () => (`
  padding-top: 210px;
`))}

.flex-wrap {
  margin-left: -24px;
  margin-right: -24px;
}

${SectionTitleMain} {
  color: #fff;
  margin-bottom: .35em;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 0.95em;
  `))}
}

.t-hex-svg {
  ${props => props.theme.max('sm', () => (`
    display: none;
  `))}
}


${props => props.theme.min('sm', () => (`
  .hexagon-container {
    position: relative;
    margin: 0 auto;
    max-width: 1515px;

  }

  ${HexagonNumberMain} {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  .t-hex {
    position: absolute;
    width: 10.7%;

    ${props => props.theme.max('lg', () => (`
      width: 12.7%;
    `))}
    
    ${props => props.theme.max('md', () => (`
      path {
        stroke-width: 1.5px;
      }
    `))}
    
    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .t-hex-0 {
    top: 9.3%;
    left: 39%;
  }

  .t-hex-1 {
    top: 18%;
    left: 72%;
  }

  .t-hex-2 {
    top: 45%;
    left: 85%;
  }

  .t-hex-3 {
    top: 71%;
    left: 68%;
  }

  .t-hex-4 {
    top: 75%;
    left: 33%;
  }
  
  .t-hex-5 {
    top: 55%;
    left: 7%;
  }

  .t-hex-6 {
    top: 26%;
    left: 10%;
  }

  .t-hex-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 26px;
    color: ${props.theme.colors.secondary100};
    white-space: nowrap;

    ${props.theme.max('lg', () => (`
      font-size: 20px;
    `))}
    
    ${props.theme.max('md', () => (`
      font-size: 16px;
    `))}
  }
`))}


.hexagon-container {
  ${props => props.theme.max('sm', () => (`
    ${containerSideOffset()}
    padding: 240px 0 90px;
  `))}
}

.t-hex-title {
  ${props => props.theme.max('sm', (props) => (`
    font-size: 22px;
    line-height: 2.2;
    text-align: center;
    color: ${props.theme.colors.secondary100};
    opacity: 0;
    transition: 400ms;
  `), props)}
}

.to-animate {
  .t-hex-title {
    transform: translate(-50%, -50%) scale(0, 0);
    opacity: 0;
  }

  .t-hex-svg {
    opacity: 0;
    transform: scale(1.5, 1.5);
  }
}

.to-animate:not(.is-in-vp) {
  .t-hex-title,
  .t-hex-svg {
    transition-delay: 0ms !important;
  }
}

.to-animate.is-in-vp {
  .t-hex-title {
    transition: ${1000}ms ease 2000ms;
    transform: translate(-50%, -50%) scale(1, 1);
    opacity: 1;

    ${props => props.theme.max('sm', () => (`
      transform: scale(1, 1);
    `))}
  }
  
  .t-hex-svg {
    opacity: 1;
    transition: ${1000}ms ease 2000ms;
    transform: scale(1, 1);
  }
}

.side-col {
  ${col(40)}
  margin-bottom: 28px;

  ${props => props.theme.max('lt', () => (`
    ${col(45)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
    margin-bottom: 0;
  `))}
}

.side-col-2 {
  margin-left: auto;
  margin-right: 12%;

  ${props => props.theme.max('lt', () => (`
    margin-right: 0;
  `))}
}

.hex-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 204px;
  padding-bottom: 266px;

  ${props => props.theme.max('sm', () => (`
    flex-direction: column;
    padding-top: 84px;
    padding-bottom: 76px;
  `))}
}

.hex-side-item {
  font-size: 26px;
  margin-bottom: 63px;

  ${props => props.theme.max('mac', () => (`
    font-size: 22px;
    margin-bottom: 33px;
  `))}

  &:last-child {
    margin-bottom: 0;
  }
}

.hex-rel-line {
  position: relative;
  flex: 0 0 8%;
  max-width: 8%;

  ${props => props.theme.max('sm', () => (`
    flex: 1 1 auto;
    height: 55px;
  `))}

  &:after {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #ece9e5;
    opacity: .2;

    ${props => props.theme.max('sm', () => (`
      width: 2px;
      height: 100%;
    `))}
  }
}

.hex-side-items {
  position: relative;
  ${props => props.theme.min('sm', () => (`
    padding-left: 7%;
  `))}

  ${props => props.theme.max('sm', () => (`
    padding-top: 50px;
    text-align: center;
  `))}
}

.hex-rel-indicator {
  position: absolute;
  height: 100%;
  left: 10px;
  top: 0;
  width: 14px;

  ${props => props.theme.max('mac', () => (`
    width: 11px;
  `))}
  
  ${props => props.theme.max('sm', () => (`
    width: 100%;
    height: 8px;
    left: 0;
    top: 7px;
  `))}

  &::before, 
  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 45%;
    background-color: #ece9e5;
    opacity: .2;
    transform: translate(-50%, 0);
  
    ${props => props.theme.min('sm', () => (`
      left: 50%;
    `))}

    ${props => props.theme.max('sm', () => (`
      height: 2px;
      width: 38%;
      transform: translate(0, -50%);
      top: 50%;
    `))}
  }

  &:before {
    ${props => props.theme.min('sm', () => (`
      top: 0;
    `))}
  
    ${props => props.theme.max('sm', () => (`
      left: 8%;
    `))}
  }
  
  &:after {
    ${props => props.theme.min('sm', () => (`
      bottom: 0;
    `))}
  
    ${props => props.theme.max('sm', () => (`
      right: 8%;
    `))}
  }
}

.to-animate {
  .hex-rel-line:after {
    ${props => props.theme.min('sm', () => (`
      transform-origin: left center;
      transform: scale(0, 1);
    `))}
    ${props => props.theme.max('sm', () => (`
      transform-origin: top center;
      transform: scale(1, 0);
    `))}
  }
  
  .hex-rel-dot {
    transform: translate(-50%, -50%) scale(0, 0);
  }

  .hex-rel-indicator {
    &:before,
    &:after {
      ${props => props.theme.min('sm', () => (`
        transform: translate(-50%, 0) scale(1, 0);
      `))}
    
      ${props => props.theme.max('sm', () => (`
        transform: translate(0, -50%) scale(0, 1);
      `))}
    }
    
    &:before {
      ${props => props.theme.min('sm', () => (`
        transform-origin: bottom center;
      `))}
      ${props => props.theme.max('sm', () => (`
        transform-origin: right center;
      `))}
    }

    &:after {
      ${props => props.theme.min('sm', () => (`
        transform-origin: top center;
      `))}
      ${props => props.theme.max('sm', () => (`
        transform-origin: left center;
      `))}

    }
  }

  .hex-side-item {
    opacity: 0;
  }
}


.to-animate.is-in-vp {
  .hex-rel-line:after {
    transition: 400ms ease 1000ms;
    transform: none;
  }

  .hex-rel-dot {
    transition: 200ms ease 1400ms;
    transform: translate(-50%, -50%) scale(1);
  }

  .hex-rel-indicator {
    &:before,
    &:after {
      transition: 400ms ease 1600ms;
      ${props => props.theme.min('sm', () => (`
        transform: translate(-50%, 0);
      `))}
      ${props => props.theme.max('sm', () => (`
        transform: translate(0, -50%);
      `))}
    }
  }

  .hex-side-item {
    transition: 400ms ease 1600ms;
    opacity: 1;
  }
}

.hex-rel-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 14px;
  width: 14px;
  border-radius: 100%;
  background-color: ${props => props.theme.colors.secondary100}; 

  ${props => props.theme.max('mac', () => (`
    height: 11px;
    width: 11px;
  `))}
  
  ${props => props.theme.max('sm', () => (`
    height: 8px;
    width: 8px;
  `))}

}
`