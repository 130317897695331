import styled from "styled-components"

import { HexagonTitleMain, SectionTextMain, SectionTitleMain, containerSideOffset, col, paddingMobile } from "../common.styles"

export const DiversityAndInclusionMain = styled.div`
padding: 44px 0 136px;

${props => props.theme.max('mb', () => (`
  padding-bottom: 0;
`))}

${HexagonTitleMain} {
  margin-bottom: 74px;
}

${SectionTitleMain} {
  margin-bottom: .36em;
}

.flex-wrap {
  ${props => props.theme.max('mac', () => (`
    align-items: center;
  `))}
}

.text-col {
  ${containerSideOffset()}
  ${col(7, 'c')}
  margin-right: auto;

  ${props => props.theme.max('md', () => (`
    ${col(50)}
  `))}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}

  ${props => props.theme.max('mb', () => (`
    order: 30;
  `))}
  
  ${SectionTextMain} {
    max-width: 480px;
  }
}

.images-col {
  display: flex;
  flex-direction: column;
}

.images-col-l {
  ${col(4.7, 'c')}
  margin-left: auto;
  margin-right: 35px;
  justify-content: center;
  padding-top: 102px;
  
  ${props => props.theme.max('sm', () => (`
    padding-left: ${paddingMobile}px;
    padding-right: ${paddingMobile / 2}px;
    margin-left: 0;
    margin-right: 0;
    ${col(50)}
  `))}
}

.images-col-r {
  ${col(4.7, 'c')}
  padding-top: 65px;
  
  ${props => props.theme.max('sm', () => (`
    padding-right: ${paddingMobile}px;
    padding-left: ${paddingMobile / 2}px;
    ${col(50)}
  `))}
}

.top-img {
  margin-bottom: 35px;
}

.desktop {
  ${props => props.theme.max('mb', () => (`
    display: none;
  `))}
}

.mobile {
  ${props => props.theme.min('mb', () => (`
    display: none;
  `))}
}
`