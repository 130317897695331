import styled from "styled-components"

import { col, containerSideOffset, HexagonTitleMain, SectionTitleMain } from "../../styles/common.styles"

export const CareersSectionMain = styled.div`
position: relative;
padding: 144px 0 106px;

${props => props.theme.max('lt', () => (`
  padding: 150px 0 150px;
`))}

${props => props.theme.max('sm', () => (`
  padding: 95px 0 0;
`))}

.image-1 {
  ${props => props.theme.min('sm', () => (`
    position: absolute;
    width: 16.7%;
    left: 0;
    top: 46.7%;
  `))}
}

.image-1-wrap {
  ${props => props.theme.max('sm', () => (`
    ${col(50)}
    padding-top: 50px;
    padding-bottom: 60px;
  `))}
}

.image-2 {
  ${props => props.theme.min('sm', () => (`
    position: absolute;
    right: 0;
    top: 10.5%;
    width: 12.5%;
  `))}
}

.image-2-wrap {
  ${props => props.theme.max('sm', () => (`
    ${containerSideOffset()}
    ${col(50)}
  `))}
}

.main-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${props => props.theme.max('sm', () => (`
    align-items: flex-start;
    ${containerSideOffset()}
  `))}
}

${HexagonTitleMain} {
  margin-bottom: 76px;
}

.hexagon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;

  &-text {
    color: ${props => props.theme.colors.secondary};
    font-size: 12px;
    text-transform: uppercase;
  }
}

.list {
  padding-top: 60px;

  ${props => props.theme.max('sm', () => (`
    padding-top: 32px;
  `))}

  ul {
    padding: 0;
    
    ${props => props.theme.max('sm', () => (`
      margin-bottom: 0;
    `))}
  }

  li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, .10);
    padding: 51px 78px;

    ${props => props.theme.max('mac', () => (`
      padding: 40px 50px;
    `))}

    ${props => props.theme.max('md', () => (`
      padding: 34px 44px;
    `))}

    ${props => props.theme.max('sm', () => (`
      flex-direction: column;
      padding: 34px 0 46px;
    `))}

    &:first-child {
      padding-top: 25px;
      padding-bottom: 25px;
      
      ${props => props.theme.max('sm', () => (`
        flex-direction: row;
        justify-content: flex-start;
      `))}

      p {
        color: ${props => props.theme.colors.primary200};
        opacity: .5;
        font-size: 16px;
      
        ${props => props.theme.max('sm', () => (`
          &:first-child {
            padding-right: .3em;
          }

          &:last-child {
            &:before {
              content: " & ";
            }
          }
        `))}
      }
    }
  }

  p {
    margin: 0;
  }

  p, 
  a {
    font-size: 22px;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.primary200};
    transition: 400ms;

    &:hover {
      opacity: .5;
    }
  }

  .city {
    ${props => props.theme.max('sm', () => (`
      font-size: 14px;
    `))}
  }

  .position {
    padding-right: 30px;
      
    ${props => props.theme.max('sm', () => (`
      padding-right: 0;
      font-size: 20px;
      line-height: 1.63;
    `))}
  }
}

${SectionTitleMain} {
  margin-bottom: 0.36em;
  text-align: center;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 1.2em;
    text-align: left;
  `))}
}

.list-wrap {
  width: 58%;

  ${props => props.theme.max('lt', () => (`
    width: 64%;
  `))}

  ${props => props.theme.max('md', () => (`
    width: 100%;
    max-width: 550px;
    padding: 0 24px;
  `))}

  ${props => props.theme.max('sm', () => (`
    max-width: 100%;
    padding: 0;
  `))}
}

&.to-animate {
  .list {
    li {
      transform: translate(0, 0);
      opacity: 0;
    }
  }
}

&.to-animate:not(.is-in-vp) {
  .list {
    li {
      transition-delay: 0ms !important;
    }
  }
}

&.to-animate.is-in-vp {
  .list {
    li {
      transition: ${1000}ms;
      transform: translate(0);
      opacity: 1;
    }
  }
}
`