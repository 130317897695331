import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { HexagonTitle } from "../../elements/hexagon-title"
import { SectionTitle } from "../../elements/section-title"
import { SectionText } from "../../elements/section-text"
import { RevealImage } from "../../elements/reveal-image"

import { DiversityAndInclusionMain } from "../../styles/careers/diversity-and-inclusion.styles"

export const DiversityAndInclusion = ({
  hexagonData: { name, sectionNum, },
  title,
  text,
  image1,
  image2,
  image3,
  image4,

}) => {
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <DiversityAndInclusionMain className={`to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="flex-wrap">
            <div className="text-col">
              <HexagonTitle number={sectionNum}>
                {name}
              </HexagonTitle>
              <SectionTitle text={title} />
              <SectionText text={text} variation="large-text" />
            </div>

            <div className="images-col images-col-l">
              <div className="img-wrap top-img">
                <RevealImage image={image1} variation={"from-right"} delay={0} />
              </div>
              <div className="img-wrap desktop">
                <RevealImage image={image2} variation={"from-right"} delay={600} />
              </div>
            </div>

            <div className="images-col images-col-r">
              <div className="img-wrap top-img">
                <RevealImage image={image3} variation={"from-left"} delay={200} />
              </div>
              <div className="img-wrap desktop">
                <RevealImage image={image4} variation={"from-left"} delay={400} />
              </div>
            </div>
          </div>

          <div className="mobile">
            <RevealImage image={image2} variation={"from-right"} delay={600} />
          </div>

        </DiversityAndInclusionMain>
      }
    </VisibilitySensor>
  )
}