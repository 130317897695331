import styled from "styled-components"

import { SectionTextMain, col, calcCol, containerSideOffset } from "../common.styles"

export const TestimonialsHeaderMain = styled.div`

.testim-header-wrap {
  padding: 150px 0 119px;
  
  ${SectionTextMain} {
    margin-bottom: 4.3em;
  }
}

.testim-header-text {
  margin-left: auto;
  margin-right: ${calcCol(14)};
  ${col(6, 'c')}

  ${props => props.theme.max('lg', () => (`
    margin-right: ${calcCol(10)};
    ${col(10, 'c')}
  `))}
  
  ${props => props.theme.max('sm', () => (`
    ${containerSideOffset()}
    ${col(100)}
  `))}
}
.img-wrap {
  margin-left: auto;
  ${col(20, 'c')}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}
}
`