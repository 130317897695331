import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { AcfLink } from "../elements/acf-link"
import { BtnInner } from "../elements/btn-inner"
import { HexSimpleNum } from "../elements/hex-simple-num"
import { SectionText } from "../elements/section-text"
import { HexagonTitle } from "../elements/hexagon-title"
import { SectionTitle } from "../elements/section-title"
import { seoClassPrefix } from "../helpers/common"
import { CareersStatsMain } from "../styles/careers-stats.styles"
import { Container } from "../styles/common.styles"
import { useAgreementState } from "../context/global-agreement-context"
import { AllThemes } from "../styles/theme.styles"

export const CareersStatsSection = ({ hexagonData: { name, sectionNum, }, title, text1, text2, linkData, listSmall, number1 }) => {
  const num1 = { ...number1, variation: 'c-stats' };

  const { popupTermsStatus } = useAgreementState();

  return (
    <CareersStatsMain>
      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }} delayedCall={true}>
        {({ isVisible }) =>
          <Container>
            <div className={`flex-wrap to-animate ${isVisible && 'accepted' === popupTermsStatus ? "is-in-vp" : ""}`}>
              <div className="main-col pad-col">
                <HexagonTitle number={sectionNum}>
                  {name}
                </HexagonTitle>
                <SectionTitle text={title} responsive={[{ breakpoint: 767, charLimit: 12 }]} />
              </div>

              <div className="pad-col side-col side-col-1">
                <SectionText text={text1} variation="large-text" />
              </div>
              <div className="pad-col side-col side-col-2">
                <SectionText text={text2} variation="large-text" />

                <AcfLink linkData={linkData} className={`btn secondary white-text ${seoClassPrefix}-careers-stats-btn`}>
                  <BtnInner text={linkData.linkText} />
                </AcfLink>

              </div>
            </div>
          </Container>
        }
      </VisibilitySensor>

      <VisibilitySensor partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
        {({ isVisible }) =>
          <div className={`hex-parent to-animate ${isVisible && 'accepted' === popupTermsStatus ? "is-in-vp" : ""}`}>
            <HexSimpleNum {...num1} isVisible={isVisible} />
            <div className="hex-rel-line"></div>
            <div className="hex-side-items">

              <div className="hex-rel-indicator">
                <div className="hex-rel-dot"></div>
              </div>

              {listSmall.map(({ title }, i) => (
                <div className={`hex-side-item hex-side-item-${i}`} key={i} style={{ transitionDelay: isVisible && typeof window !== 'undefined' ? window.innerWidth > AllThemes?.breakpoints?.sm ? `${1600 + Math.abs(Math.ceil(listSmall?.length / 2) - i - 1) * 200}ms` : `${1600 + i * 200}ms` : '' }}>
                  <div className="hex-side-item-title" >
                    {title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
      </VisibilitySensor>
    </CareersStatsMain>
  )
}