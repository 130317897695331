import React from "react"
import VisibilitySensor from "react-visibility-sensor"

import { BtnInner } from "../../elements/btn-inner"

import { HexagonTitle } from "../../elements/hexagon-title"
import { SectionTitle } from "../../elements/section-title"
import { SectionText } from "../../elements/section-text"
import { RevealImage } from "../../elements/reveal-image"

import { JoinOurTeamMain } from "../../styles/careers/join-our-team.styles"
import { seoClassPrefix } from "../../helpers/common"
import { AcfLink } from "../../elements/acf-link"

export const JoinOurTeamCareers = ({
  hexagonData: { name, sectionNum, },
  title,
  items,
}) => {
  return (
    <VisibilitySensor partialVisibility={true} offset={{ top: 100, bottom: 100 }}>
      {({ isVisible }) =>
        <JoinOurTeamMain className={`cutout-top to-animate ${isVisible ? 'is-in-vp' : ''}`}>
          <div className="title-col">
            <HexagonTitle number={sectionNum}>
              {name}
            </HexagonTitle>
            <SectionTitle text={title} responsive={[{ breakpoint: 600, charLimit: 13 }]} />
          </div>

          <div className="flex-wrap">

            {items.map(({ title, text, acfLink, image }, i) => (
              <div className={`${i % 2 === 0 ? 'article-col-l' : 'article-col-r'}`} key={i}>
                <div className="img-wrap">
                  <RevealImage image={image} variation="from-left" delay={i * 200} />
                </div>
                <div className="article-body">
                  <SectionTitle text={title} variation="smallest-title" delay={100 + i * 200} />
                  <SectionText text={text} variation="small-text" delay={200 + i * 200} />
                  <AcfLink linkData={acfLink} className={`btn white mobile ${seoClassPrefix}careers-join-our-team-link-${i}`}>
                    <BtnInner text={acfLink.linkText} />
                  </AcfLink>
                </div>
              </div>
            ))}

          </div>
        </JoinOurTeamMain>
      }
    </VisibilitySensor>
  )
}
